import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { CONSTANTS } from '../../../shared/constants';
import { ApiResponse } from '../../../shared/interfaces/api-response.interface';
import { SharedComponentViewType } from '../../../shared/interfaces/common.interface';
import { IDropdownItem } from '../../../shared/interfaces/dropdown/dropdown-item.interface';
import {
  I18nAvailableLanguage,
  I18nCountriesAndTimeZonesDropdown,
  I18nResponseLanguageEnable,
  I18nSupportedCountry,
  I18nSupportedLanguage,
  I18nTimezone,
  I18nTimeZonesByCountry,
  I18nValidtion,
} from '../../../shared/interfaces/i18n.interface';
import { ApiService } from '../api/api.service';
import { ContextService } from '../context/context.service';

@Injectable()
export class I18nBackendService {
  private i18nPath = `${environment.apiBaseUrl.handbook}/i18n`;
  private cachedSupportedLanguages: I18nSupportedLanguage[] = [];
  private lastContextUuid: string;
  private supportedLanguagesRequest$;
  private defaultLanguageRequest$;

  constructor(
    private apiService: ApiService,
    private translateService: TranslateService,
    private contextService: ContextService
  ) {
    this.contextService.onSessionContextUuidChange$.subscribe(() =>
      this.resetCache()
    );
  }

  getAllSupportedCountries$(): Observable<I18nSupportedCountry[]> {
    return this.apiService
      .get$(`${this.i18nPath}/${CONSTANTS.ENDPOINTS.COUNTRIES}`)
      .pipe(map((response: ApiResponse<any>) => response.content));
  }

  getCountriesWithTimeZones$(): Observable<I18nCountriesAndTimeZonesDropdown> {
    return this.getAllSupportedCountries$().pipe(
      map((supportedCountries: I18nSupportedCountry[]) => {
        const countries: IDropdownItem[] = [];
        const timeZonesByCountry: I18nTimeZonesByCountry[] = [];

        supportedCountries.forEach((supportedCountry: I18nSupportedCountry) => {
          countries.push({
            id: supportedCountry.code,
            text: supportedCountry.name,
          });
          const availableTimeZones: IDropdownItem[] =
            supportedCountry.timezones.map((zone: I18nTimezone) => ({
              id: zone.timezone,
              text: `${zone.gmtDifference} ${zone.timezone}`,
              value: zone.timezone,
            }));

          timeZonesByCountry.push({
            availableTimeZones,
            code: supportedCountry.code,
          });
        });

        return {
          countries,
          timeZonesByCountry,
        };
      })
    );
  }

  getAllSupportedLanguages$(): Observable<I18nSupportedLanguage[]> {
    return this.apiService
      .get$(`${this.i18nPath}/${CONSTANTS.ENDPOINTS.LANGUAGES}`)
      .pipe(map((response: ApiResponse<any>) => response.content));
  }

  // TODO remove it
  getSupportedLanguagesForAccount$(
    uuid: string
  ): Observable<I18nSupportedLanguage[]> {
    return this.apiService
      .get$(
        `${this.i18nPath}/${CONSTANTS.ENDPOINTS.ACCOUNTS}/${uuid}/${CONSTANTS.ENDPOINTS.LANGUAGES}`
      )
      .pipe(
        map((response: ApiResponse<any>) => response.content),
        map((supportedLanguages: I18nSupportedLanguage[]) =>
          supportedLanguages.filter(
            (lang: I18nAvailableLanguage) => lang.isActive
          )
        )
      );
  }

  getSupportedLanguages$(
    uuid: string,
    contextType: string
  ): Observable<I18nSupportedLanguage[]> {
    return this.apiService
      .get$(
        `${this.i18nPath}/${this.getContextEndpoint(contextType)}/${uuid}/${
          CONSTANTS.ENDPOINTS.LANGUAGES
        }`
      )
      .pipe(map((response: ApiResponse<any>) => response.content));
  }

  getLanguageValidationStatus$(
    uuid: string,
    contextType: string
  ): Observable<I18nValidtion[]> {
    return this.apiService
      .get$(
        `${this.i18nPath}/${this.getContextEndpoint(contextType)}/${uuid}/${
          CONSTANTS.ENDPOINTS.TRANSLATION_COVERAGE
        }`
      )
      .pipe(map((response: ApiResponse<any>) => response.content));
  }

  activateOrDeactivateLanguage$(
    uuid: string,
    contextType: string,
    code: string,
    isActive: boolean
  ): Observable<any> {
    this.resetCache();

    return this.apiService
      .put$(
        `${this.i18nPath}/${this.getContextEndpoint(contextType)}/${uuid}/${
          CONSTANTS.ENDPOINTS.LANGUAGES
        }/${code}/${isActive ? 'activate' : 'deactivate'}`,
        {
          code,
        }
      )
      .pipe(map((response: ApiResponse<any>) => response));
  }

  enforceOrUnforceLanguage$(
    uuid: string,
    code: string,
    isEnforced: boolean
  ): Observable<I18nResponseLanguageEnable> {
    this.resetCache();

    return this.apiService
      .put$(
        `${this.i18nPath}/${CONSTANTS.ENDPOINTS.ACCOUNTS}/${uuid}/${
          CONSTANTS.ENDPOINTS.LANGUAGES
        }/${code}/${isEnforced ? 'enforce' : 'unforce'}`,
        {
          code,
        }
      )
      .pipe(map((response: ApiResponse<any>) => response.content));
  }

  setDefaultLanguage$(
    uuid: string,
    contextType: string,
    code: string
  ): Observable<I18nResponseLanguageEnable> {
    this.resetCache();

    return this.apiService
      .put$(
        `${this.i18nPath}/${this.getContextEndpoint(contextType)}/${uuid}/${
          CONSTANTS.ENDPOINTS.LANGUAGES
        }/${code}/default`,
        {
          code,
        }
      )
      .pipe(map((response: ApiResponse<any>) => response.content));
  }

  getSupportedLanguagesForContext$(
    uuid: string,
    viewType: SharedComponentViewType
  ): Observable<I18nSupportedLanguage[]> {
    if (this.supportedLanguagesRequest$ && this.lastContextUuid === uuid) {
      return this.supportedLanguagesRequest$;
    }
    const contextPathEndpoint: string =
      viewType === SharedComponentViewType.ACCOUNT
        ? CONSTANTS.ENDPOINTS.ACCOUNTS
        : CONSTANTS.ENDPOINTS.VENUE;

    this.lastContextUuid = uuid;
    this.supportedLanguagesRequest$ = this.apiService
      .get$(
        `${this.i18nPath}/${contextPathEndpoint}/${uuid}/${CONSTANTS.ENDPOINTS.LANGUAGES}`
      )
      .pipe(
        map((response: ApiResponse<any>) => response.content),
        map((supportedLanguages: I18nSupportedLanguage[]) =>
          supportedLanguages.filter(
            (lang: I18nAvailableLanguage) => lang.isActive
          )
        ),
        share()
      );

    return this.supportedLanguagesRequest$;
  }

  getDefaultLanguageForContext$(uuid: string, viewType: SharedComponentViewType): Observable<I18nSupportedLanguage> {
    if (this.defaultLanguageRequest$ && this.lastContextUuid === uuid) {
      return this.defaultLanguageRequest$;
    }
    const contextPathEndpoint: string =
      viewType === SharedComponentViewType.ACCOUNT ? CONSTANTS.ENDPOINTS.ACCOUNTS : CONSTANTS.ENDPOINTS.VENUE;

    this.lastContextUuid = uuid;
    this.supportedLanguagesRequest$ = this.apiService
      .get$(`${this.i18nPath}/${contextPathEndpoint}/${uuid}/${CONSTANTS.ENDPOINTS.LANGUAGES}/default`)
      .pipe(
        map((response: ApiResponse<any>) => response.content),
        share()
      );

    return this.supportedLanguagesRequest$;
  }

  resetCache(): void {
    this.lastContextUuid = null;
    this.supportedLanguagesRequest$ = null;
  }

  getSupportedLanguagesForVenue$(
    uuid: string
  ): Observable<I18nSupportedLanguage[]> {
    return this.apiService
      .get$(
        `${this.i18nPath}/${CONSTANTS.ENDPOINTS.VENUE}/${uuid}/${CONSTANTS.ENDPOINTS.LANGUAGES}`
      )
      .pipe(
        map((response: ApiResponse<any>) => response.content),
        map((supportedLanguages: I18nSupportedLanguage[]) =>
          supportedLanguages.filter(
            (lang: I18nAvailableLanguage) => lang.isActive
          )
        )
      );
  }
  getTranslatedLanguageName(langCode: string) {
    return this.translateService.instant(
      `SHARED.LANGUAGES.${langCode.toUpperCase()}`
    );
  }

  sortLanguagesByNameAsc(
    languages: I18nSupportedLanguage[]
  ): I18nSupportedLanguage[] {
    return languages
      .slice()
      .sort((a: I18nSupportedLanguage, b: I18nSupportedLanguage) =>
        a.name.localeCompare(b.name)
      );
  }

  private getContextEndpoint(contextType: string): string {
    return contextType === CONSTANTS.CONTEXT.ACCOUNT
      ? CONSTANTS.ENDPOINTS.ACCOUNTS
      : CONSTANTS.ENDPOINTS.VENUE;
  }
}
