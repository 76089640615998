import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import * as Moment from 'moment';
import { DateRange as MomentDateRange, extendMoment } from 'moment-range';
import { DateGroupPickerValue } from '../../shared/components/date-range-picker';
import { FoodbacksScoreChartLabelsAndDates } from '../../shared/components/overview/foodbacks-score-chart/foodbacks-score-chart.interface';
import { DynamicDateRange } from '../../shared/interfaces/date-range.interface';
import {
  ChartHourRangeService,
  ChartMonthDayRangeService,
  ChartMonthRangeService,
  ChartRangeGenerator,
  ChartWeekDayRangeService,
} from '../providers/charts';
import { ChartCustomRangeService } from '../providers/charts/chart-custom-range.service';
import { ChartTooltipIntervalAndFormat } from '../providers/charts/chart-range-generator.interface';
import { DomService } from '../providers/dom/dom.service';
import { forEach, initial } from 'lodash';
import { count } from 'console';

const moment = extendMoment(Moment);

@Injectable()
export class ChartUtilsService {
  private toolTipBgColor = '#000000';
  private toolTipBorderColor = '#000000';
  private toolTipColor = '#ffffff';
  private baseFont = 'Komet, sans-serif';
  private divlineColor = '#999999';
  private legendItemHiddenColor = '#e2e2e2';

  constructor(private titleCasePipe: TitleCasePipe) {}

  static onChartHover(mouseEvent: MouseEvent, activePoints: any[]) {
    if (activePoints.length) {
      DomService.addClass(mouseEvent.target as HTMLElement, ['chart-clickable']);
    } else {
      DomService.removeClass(mouseEvent.target as HTMLElement, ['chart-clickable']);
    }
  }

  getChartConfiguration(additionalOptions?: any) {
    let config = {
      baseFont: this.baseFont,
      crossLineAlpha: 20,
      toolTipBgColor: this.toolTipBgColor,
      toolTipBorderColor: this.toolTipBorderColor,
      toolTipBgAlpha: '80',
      toolTipColor: this.toolTipColor,
      legendItemHiddenColor: this.legendItemHiddenColor,
      numberprefix: '',
      theme: 'fusion',
      divlineAlpha: '20',
      divlineThickness: '1',
      divLineDashLen: '1',
      divLineGapLen: '1',
      numvdivlines: '0',
      labelDisplay: '0',
      useEllipsesWhenOverflow: '1',
      setAdaptiveYMin: '1',
    };

    if (additionalOptions) {
      config = { ...config, ...additionalOptions };
    }

    return config;
  }

  initChartWithData(additionalOptions?: any) {
    return {
      divlineColor: this.divlineColor,
      data: [],
      chart: this.getChartConfiguration(additionalOptions),
    };
  }

  initChartWithDataSetAndCategories(additionalOptions?: any) {
    return {
      divlineColor: this.divlineColor,
      categories: [],
      dataset: [],
      chart: this.getChartConfiguration(additionalOptions),
    };
  }

  getChartParametersForRange(range: MomentDateRange, group: DateGroupPickerValue): ChartRangeGenerator {
    switch (group) {
      case DateGroupPickerValue.YEAR:
        return new ChartMonthRangeService(range);
      case DateGroupPickerValue.MONTH:
        return new ChartMonthDayRangeService(range);
      case DateGroupPickerValue.WEEK:
        return new ChartWeekDayRangeService(range);
      case DateGroupPickerValue.DAY:
        return new ChartHourRangeService(range);
      case DateGroupPickerValue.CUSTOM:
      default: // falls through
        return ChartCustomRangeService.createRangeGenerator(range);
    }
  }

  getIntervalAndFormatForTooltipTitle(dateRange: DynamicDateRange): ChartTooltipIntervalAndFormat {
    const range = moment.range(dateRange.start, dateRange.end);

    switch (dateRange.groupBy) {
      case DateGroupPickerValue.YEAR:
        return new ChartMonthRangeService(range).getIntervalAndFormatForTooltip();
      case DateGroupPickerValue.MONTH:
        return new ChartMonthDayRangeService(range).getIntervalAndFormatForTooltip();
      case DateGroupPickerValue.WEEK:
        return new ChartWeekDayRangeService(range).getIntervalAndFormatForTooltip();
      case DateGroupPickerValue.DAY:
        return new ChartHourRangeService(range).getIntervalAndFormatForTooltip();
      case DateGroupPickerValue.CUSTOM:
      default:
        return ChartCustomRangeService.createRangeGenerator(range).getIntervalAndFormatForTooltip();
    }
  }

  mapScoresToChartData<T extends { date: string }>(scores: T[], chartRangeGenerator: ChartRangeGenerator): T[] {
    return chartRangeGenerator.getDates().map(date => {
      const scoreWithSameDate = scores.find(score => chartRangeGenerator.shouldDateDisplayAtSameIndex(date, moment(score.date).toDate()));

      return scoreWithSameDate || null;
    });
  }

  mapAverageScoresToChartData<T extends { date: string }>(scores: any[], chartRangeGenerator: ChartRangeGenerator): T[] {
        return chartRangeGenerator.getDates().map(date => {
          let objectWithSameDate = scores.find(score => chartRangeGenerator.shouldDateDisplayAtSameIndex(date, moment(score.date).toDate()));
          let initialScore = 0;
          let initialCount = 0;
          scores.forEach((element) => {
            if (chartRangeGenerator.shouldDateDisplayAtSameIndex(date, moment(element.date).toDate())) {
              initialScore = initialScore + element.score * element.count;
            } 
          });
          scores.forEach((element) => {
            if (chartRangeGenerator.shouldDateDisplayAtSameIndex(date, moment(element.date).toDate())) {
              initialCount = initialCount + element.count;
            } 
          });
          if (objectWithSameDate) {
            objectWithSameDate.count = initialCount;
            objectWithSameDate.score = initialScore / initialCount;
          }

          return objectWithSameDate || null;
        })
  }


  mapPreviousScoresToChartData<T extends { date: string }>(scores: T[], chartRangeGenerator: ChartRangeGenerator): T[] {
    return chartRangeGenerator.getDates().map(date => {
      const scoreWithSameDate = scores.find(score =>
        chartRangeGenerator.shouldPreviousDateDisplayAtSameIndex(date, moment(score.date).toDate())
      );

      return scoreWithSameDate || null;
    });
  }

  getTooltipTextForFirstItem(): string {
    return '<div class="plottooltext-label">$label</div> $seriesName: $dataValue';
  }

  getTooltipTextForItem(): string {
    return '$seriesName: $dataValue';
  }

  getSimpleTooltipTextForItem(): string {
    return '$dataValue';
  }

  prepareDateForRequest(date: Date): string {
    const day: number = date.getDate();
    const month: number = date.getMonth() + 1;

    return `${date.getFullYear()}-${this.prepareDayOrMonthString(month)}-${this.prepareDayOrMonthString(day)}`;
  }

  getChartRangeGenerator(dateRange: DynamicDateRange): ChartRangeGenerator {
    const range: MomentDateRange = moment.range(
      moment(this.prepareDateForRequest(dateRange.start)),
      moment(this.prepareDateForRequest(dateRange.end))
    );

    range.start.set({ hour: 0, minute: 0, second: 0 });
    range.end.set({ hour: 23, minute: 59, second: 59 });

    return this.getChartParametersForRange(range, dateRange.groupBy);
  }

  getChartLabelsAndDates(dateRange: DynamicDateRange): FoodbacksScoreChartLabelsAndDates {
    const chartRangeGenerator: ChartRangeGenerator = this.getChartRangeGenerator(dateRange);

    return {
      chartLabels: chartRangeGenerator.getChartLabels().map(title => this.titleCasePipe.transform(title)),
      currentPeriodDates: chartRangeGenerator.getDates(),
    };
  }

  getHoursForDay(): any[] {
    return [
      '00:00','01:00', '02:00','03:00', '04:00','05:00', '06:00','07:00', '08:00','09:00', 
      '10:00','11:00', '12:00','13:00', '14:00','15:00', '16:00','17:00', '18:00','19:00', 
      '20:00','21:00', '22:00','23:00'
    ]
  }

  private prepareDayOrMonthString(value: number): string {
    const pom = 10;

    return `${value < pom ? `0${value}` : value}`;
  }
}
